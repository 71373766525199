@import '~react-image-gallery/styles/css/image-gallery.css';

* {
  box-sizing: border-box;
}

body {
  font-family: 'WorkSans';
  font-weight: 400;
}

html {
  font-size: 16px;
}

/* Popover  */
.css-115qmh4-MuiPaper-root-MuiPopover-paper:not([class*='ignore-padding']) {
  padding: 0 !important;
}
